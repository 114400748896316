import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { getDurationFromSeconds } from "../../utils/secondsToDuration";
import { InfinitySpin } from "react-loader-spinner";

import "plyr-react/plyr.css";
import MyVimeo from "./MyVimeo";
import VideoGroup from "./VideoGroup";
import VideoSection from "./VideoSection";

const baseURL = process.env.REACT_APP_BACKEND_API_URL + "/videos/";

const VideoList = () => {
  const [videos, setVideos] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [videoSource, setVideoSource] = useState(null);

  const [videoTitle, setVideoTitle] = useState(null);
  const [videoCategory, setVideoCategory] = useState(null);
  const [videoDuration, setVideoDuration] = useState(null);
  const [embedHtml, setEmbedHtml] = useState(null);
  const [videoPaddingBottom, setVideoPaddingBottom] = useState(0);
  const [videoUri, setVideoUri] = useState(null);

  const setters = {
    setVideoSource,
    setIsVideoOpen,
    setVideoPaddingBottom,
    setVideoCategory,
    setVideoTitle,
    setVideoDuration,
  };

  const videoCategories = [
    { title: "Découverte", tag: "decouverte" },
    {
      title: "Fichiers de base",
      subcategories: [
        { title: "Clients", tag: "clients" },
        { title: "Animaux", tag: "animaux" },
        { title: "Fournisseurs", tag: "fournisseurs" },
        { title: "Médicaments, actes, données médicales", tag: "medicaments" },
      ],
    },
    {
      title: "Gestion médicale",
      subcategories: [
        { title: "Prestations", tag: "prestations" },
        { title: "Facturation", tag: "facturation" },
      ],
    },
    { title: "Comptabilité", tag: "comptabilite" },
    {
      title: "Gestion de stock",
      subcategories: [{ title: "Stock", tag: "stock" }],
    },
    { title: "Avancé", tag: "avance" },
  ];

  useEffect(() => {
    const fetchVideos = () => {
      setIsLoading(true);
      axios
        .get(baseURL, {
          headers: {
            "Content-Type": "application/json",
            Origin: "https://www.byvets.be", // Replace with your frontend origin
            // Add any other headers as needed
          },
        })
        .then((response) => {
          setIsLoading(false);
          setVideos(response.data.body.data);
        });
    };

    fetchVideos();
  }, []);

  const setHidden = () => {
    if (document.body.style.overflow !== "hidden") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  };

  if (isLoading)
    return (
      <div className="flex flex-col justify-center items-center">
        <p>Chargement des vidéos en cours ...</p>
        <InfinitySpin width="200" color="#e65300" />
      </div>
    );
  if (!videos) return null;
  return (
    <>
      {videoCategories.map((category, index) => {
        const hasSubcategories = !!category.subcategories;
        if (hasSubcategories) {
          return (
            <VideoGroup
              key={index}
              category={category}
              videos={videos}
              setters={setters}
            />
          );
        }
        return (
          <VideoSection
            key={index}
            category={category}
            isSubCategory={false}
            videos={videos}
            setters={setters}
          />
        );
      })}
      {/* <h3 className="px-10 text-midnight-blue-500 text-xl font-semibold mb-4">
        Général
      </h3>
      <div className="grid sm:grid-cols-3 px-10 sm:px-20 gap-x-14 gap-y-8 mb-10">
        {videos.length > 0 &&
          videos
            .filter((video) =>
              video.tags.some((tag) => tag.name === "generale")
            )
            .map((video) => {
              return (
                <div
                  className="video-link border border-trinidad-500 group transition-all hover:border-transparent hover:border-opacity-0 rounded-3xl p-3 cursor-pointer"
                  onClick={() => {
                    console.log("src", video.uri.split("/")[2]);
                    setVideoSource(video.uri.split("/")[2]);
                    setIsVideoOpen(true);
                    setVideoPaddingBottom(
                      100 - (video.height / video.width) * 100
                    );
                    setVideoCategory("Général");
                    setVideoTitle(video.name);
                    setVideoDuration(getDurationFromSeconds(video.duration));
                    // setEmbedHtml(video.embed.html);
                  }}
                >
                  <div className="relative">
                    <div
                      className="rounded-2xl grayscale hover:grayscale-0"
                      style={{
                        backgroundImage: `url(${video.pictures.base_link})`,
                        height: "150px",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>
                    <p className=" absolute bottom-0 right-0 mr-2 mb-2 inline-block px-1.5 text-white text-xs  bg-midnight-blue-500 rounded-full grayscale-0">
                      {getDurationFromSeconds(video.duration)}
                    </p>
                  </div>

                  <h4 className="text-midnight-blue-500 text-xs font-semibold  p-2">
                    {video.name}
                  </h4>
                  <div className="grid grid-cols-2 px-2 text-sm font-light text-blue-gray-500">
                    <div>
                      {new Date(video.release_time).toLocaleDateString(
                        "fr-BE",
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
      <h3 className="px-10 text-midnight-blue-500 text-xl font-semibold mb-4">
        Découverte
      </h3>
      <div className="grid sm:grid-cols-3 px-10 sm:px-20 gap-x-14 gap-y-8 mb-10">
        {videos.length > 0 &&
          videos
            .filter((video) =>
              video.tags.some((tag) => tag.name === "decouverte")
            )
            .map((video) => {
              return (
                <div
                  className="video-link border border-trinidad-500 group transition-all hover:border-transparent hover:border-opacity-0 rounded-3xl p-3 cursor-pointer"
                  onClick={() => {
                    console.log("src", video.uri.split("/")[2]);
                    setVideoSource(video.uri.split("/")[2]);
                    setIsVideoOpen(true);
                    setVideoPaddingBottom(
                      100 - (video.height / video.width) * 100
                    );
                    setVideoCategory("Découverte");
                    setVideoTitle(video.name);
                    setVideoDuration(getDurationFromSeconds(video.duration));
                    // setEmbedHtml(video.embed.html);
                  }}
                >
                  <div className="relative">
                    <div
                      className="rounded-2xl grayscale hover:grayscale-0"
                      style={{
                        backgroundImage: `url(${video.pictures.base_link})`,
                        height: "150px",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>
                    <p className=" absolute bottom-0 right-0 mr-2 mb-2 inline-block px-1.5 text-white text-xs  bg-midnight-blue-500 rounded-full grayscale-0">
                      {getDurationFromSeconds(video.duration)}
                    </p>
                  </div>

                  <h4 className="text-midnight-blue-500 text-xs font-semibold  p-2">
                    {video.name}
                  </h4>
                  <div className="grid grid-cols-2 px-2 text-sm font-light text-blue-gray-500">
                    <div>
                      {new Date(video.release_time).toLocaleDateString(
                        "fr-BE",
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
      <h3 className="px-10 text-midnight-blue-500 text-xl font-semibold mb-4">
        Fichiers de base
      </h3>

      <h4 className="px-10 text-midnight-blue-500 text-base font-medium mb-2">
        Animaux
      </h4>
      <div className="grid sm:grid-cols-3 px-10 sm:px-20 gap-x-14 gap-y-8 mb-10">
        {videos.length > 0 &&
          videos
            .filter((video) =>
              video.tags.some(
                (tag) => tag.name === "animaux" || tag.name === "animal"
              )
            )
            .map((video) => {
              return (
                <div
                  className="video-link border border-trinidad-500 group transition-all hover:border-transparent hover:border-opacity-0 rounded-3xl p-3 cursor-pointer"
                  onClick={() => {
                    setVideoSource(video.uri.split("/")[2]);
                    setIsVideoOpen(true);
                    setVideoPaddingBottom(
                      100 - (video.height / video.width) * 100
                    );

                    setVideoCategory("Animaux");
                    setVideoTitle(video.name);
                    setVideoDuration(getDurationFromSeconds(video.duration));
                    setEmbedHtml(video.embed.html);
                  }}
                >
                  <div className="relative">
                    <div
                      className="rounded-2xl grayscale hover:grayscale-0"
                      style={{
                        backgroundImage: `url(${video.pictures.base_link})`,
                        height: "150px",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>
                    <p className=" absolute bottom-0 right-0 mr-2 mb-2 inline-block px-1.5 text-white text-xs  bg-midnight-blue-500 rounded-full grayscale-0">
                      {getDurationFromSeconds(video.duration)}
                    </p>
                  </div>

                  <h4 className="text-midnight-blue-500 text-xs font-semibold  p-2">
                    {video.name}
                  </h4>
                  <div className="grid grid-cols-2 px-2 text-sm font-light text-blue-gray-500">
                    <div>
                      {new Date(video.release_time).toLocaleDateString(
                        "fr-BE",
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
      <h4 className="px-10 text-midnight-blue-500 text-base font-medium mb-2">
        Clients
      </h4>
      <div className="grid sm:grid-cols-3 px-10 sm:px-20 gap-x-14 gap-y-8 mb-10">
        {videos.length > 0 &&
          videos
            .filter((video) =>
              video.tags.some(
                (tag) => tag.name === "client" || tag.name === "clients"
              )
            )
            .map((video) => {
              return (
                <div
                  className="video-link border border-trinidad-500 group transition-all hover:border-transparent hover:border-opacity-0 rounded-3xl p-3 cursor-pointer"
                  onClick={() => {
                    setVideoSource(video.uri.split("/")[2]);
                    setIsVideoOpen(true);
                    setVideoPaddingBottom(
                      100 - (video.height / video.width) * 100
                    );

                    setVideoCategory("Clients");
                    setVideoTitle(video.name);
                    setVideoDuration(getDurationFromSeconds(video.duration));
                    setEmbedHtml(video.embed.html);
                  }}
                >
                  <div className="relative">
                    <div
                      className="rounded-2xl grayscale hover:grayscale-0"
                      style={{
                        backgroundImage: `url(${video.pictures.base_link})`,
                        height: "150px",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>
                    <p className=" absolute bottom-0 right-0 mr-2 mb-2 inline-block px-1.5 text-white text-xs  bg-midnight-blue-500 rounded-full grayscale-0">
                      {getDurationFromSeconds(video.duration)}
                    </p>
                  </div>

                  <h4 className="text-midnight-blue-500 text-xs font-semibold  p-2">
                    {video.name}
                  </h4>
                  <div className="grid grid-cols-2 px-2 text-sm font-light text-blue-gray-500">
                    <div>
                      {new Date(video.release_time).toLocaleDateString(
                        "fr-BE",
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
      <h4 className="px-10 text-midnight-blue-500 text-base font-medium mb-2">
        Fournisseurs
      </h4>
      <div className="grid sm:grid-cols-3 px-10 sm:px-20 gap-x-14 gap-y-8 mb-10">
        {videos.length > 0 &&
          videos
            .filter((video) =>
              video.tags.some(
                (tag) =>
                  tag.name === "fournisseur" || tag.name === "fournisseurs"
              )
            )
            .map((video) => {
              return (
                <div
                  className="video-link border border-trinidad-500 group transition-all hover:border-transparent hover:border-opacity-0 rounded-3xl p-3 cursor-pointer"
                  onClick={() => {
                    setVideoSource(video.uri.split("/")[2]);
                    setIsVideoOpen(true);
                    setVideoPaddingBottom(
                      100 - (video.height / video.width) * 100
                    );

                    setVideoCategory("Fournisseurs");
                    setVideoTitle(video.name);
                    setVideoDuration(getDurationFromSeconds(video.duration));
                    setEmbedHtml(video.embed.html);
                  }}
                >
                  <div className="relative">
                    <div
                      className="rounded-2xl grayscale hover:grayscale-0"
                      style={{
                        backgroundImage: `url(${video.pictures.base_link})`,
                        height: "150px",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>
                    <p className=" absolute bottom-0 right-0 mr-2 mb-2 inline-block px-1.5 text-white text-xs  bg-midnight-blue-500 rounded-full grayscale-0">
                      {getDurationFromSeconds(video.duration)}
                    </p>
                  </div>

                  <h4 className="text-midnight-blue-500 text-xs font-semibold  p-2">
                    {video.name}
                  </h4>
                  <div className="grid grid-cols-2 px-2 text-sm font-light text-blue-gray-500">
                    <div>
                      {new Date(video.release_time).toLocaleDateString(
                        "fr-BE",
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
      <h4 className="px-10 text-midnight-blue-500 text-base font-medium mb-2">
        Médicament - acte
      </h4>
      <div className="grid sm:grid-cols-3 px-10 sm:px-20 gap-x-14 gap-y-8 mb-10">
        {videos.length > 0 &&
          videos
            .filter((video) =>
              video.tags.some((tag) => tag.name === "medicament")
            )
            .map((video) => {
              return (
                <div
                  className="video-link border border-trinidad-500 group transition-all hover:border-transparent hover:border-opacity-0 rounded-3xl p-3 cursor-pointer"
                  onClick={() => {
                    setVideoSource(video.uri.split("/")[2]);
                    setIsVideoOpen(true);
                    setVideoPaddingBottom(
                      100 - (video.height / video.width) * 100
                    );
                    setVideoCategory("Médicatment - Acte");
                    setVideoTitle(video.name);
                    setVideoDuration(getDurationFromSeconds(video.duration));
                  }}
                >
                  <div className="relative">
                    <div
                      className="rounded-2xl grayscale hover:grayscale-0"
                      style={{
                        backgroundImage: `url(${video.pictures.base_link})`,
                        height: "150px",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>
                    <p className=" absolute bottom-0 right-0 mr-2 mb-2 inline-block px-1.5 text-white text-xs  bg-midnight-blue-500 rounded-full grayscale-0">
                      {getDurationFromSeconds(video.duration)}
                    </p>
                  </div>

                  <h4 className="text-midnight-blue-500 text-xs font-semibold  p-2">
                    {video.name}
                  </h4>
                  <div className="grid grid-cols-2 px-2 text-sm font-light text-blue-gray-500">
                    <div>
                      {new Date(video.release_time).toLocaleDateString(
                        "fr-BE",
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
      <h4 className="px-10 text-midnight-blue-500 text-base font-medium mb-2">
        Préstations
      </h4>
      <div className="grid sm:grid-cols-3 px-10 sm:px-20 gap-x-14 gap-y-8 mb-10">
        {videos.length > 0 &&
          videos
            .filter((video) =>
              video.tags.some((tag) => tag.name === "prestation")
            )
            .map((video) => {
              return (
                <div
                  className="video-link border border-trinidad-500 group transition-all hover:border-transparent hover:border-opacity-0 rounded-3xl p-3 cursor-pointer"
                  onClick={() => {
                    setVideoSource(video.uri.split("/")[2]);
                    setIsVideoOpen(true);
                    setVideoPaddingBottom(
                      100 - (video.height / video.width) * 100
                    );
                    setVideoCategory("Préstations");
                    setVideoTitle(video.name);
                    setVideoDuration(getDurationFromSeconds(video.duration));
                  }}
                >
                  <div className="relative">
                    <div
                      className="rounded-2xl grayscale hover:grayscale-0"
                      style={{
                        backgroundImage: `url(${video.pictures.base_link})`,
                        height: "150px",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>
                    <p className=" absolute bottom-0 right-0 mr-2 mb-2 inline-block px-1.5 text-white text-xs  bg-midnight-blue-500 rounded-full grayscale-0">
                      {getDurationFromSeconds(video.duration)}
                    </p>
                  </div>

                  <h4 className="text-midnight-blue-500 text-xs font-semibold  p-2">
                    {video.name}
                  </h4>
                  <div className="grid grid-cols-2 px-2 text-sm font-light text-blue-gray-500">
                    <div>
                      {new Date(video.release_time).toLocaleDateString(
                        "fr-BE",
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
      <h3 className="px-10 text-midnight-blue-500 text-xl font-semibold mb-4">
        Facturation
      </h3>
      <div className="grid sm:grid-cols-3 px-10 sm:px-20 gap-x-14 gap-y-8 mb-10">
        {videos.length > 0 &&
          videos
            .filter((video) =>
              video.tags.some((tag) => tag.name === "facturation")
            )
            .map((video) => {
              return (
                <div
                  className="video-link border border-trinidad-500 group transition-all hover:border-transparent hover:border-opacity-0 rounded-3xl p-3 cursor-pointer"
                  onClick={() => {
                    setVideoSource(video.uri.split("/")[2]);
                    setIsVideoOpen(true);
                    setVideoPaddingBottom(
                      100 - (video.height / video.width) * 100
                    );

                    setVideoCategory("Facturation");
                    setVideoTitle(video.name);
                    setVideoDuration(getDurationFromSeconds(video.duration));
                  }}
                >
                  <div className="relative">
                    <div
                      className="rounded-2xl grayscale hover:grayscale-0"
                      style={{
                        backgroundImage: `url(${video.pictures.base_link})`,
                        height: "150px",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>
                    <p className=" absolute bottom-0 right-0 mr-2 mb-2 inline-block px-1.5 text-white text-xs  bg-midnight-blue-500 rounded-full grayscale-0">
                      {getDurationFromSeconds(video.duration)}
                    </p>
                  </div>

                  <h4 className="text-midnight-blue-500 text-xs font-semibold  p-2">
                    {video.name}
                  </h4>
                  <div className="grid grid-cols-2 px-2 text-sm font-light text-blue-gray-500">
                    <div>
                      {new Date(video.release_time).toLocaleDateString(
                        "fr-BE",
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
      <h3 className="px-10 text-midnight-blue-500 text-xl font-semibold mb-4">
        Comptabilité
      </h3>
      <div className="grid sm:grid-cols-3 px-10 sm:px-20 gap-x-14 gap-y-8 mb-10">
        {videos.length > 0 &&
          videos
            .filter((video) =>
              video.tags.some((tag) => tag.name === "comptabilite")
            )
            .map((video) => {
              return (
                <div
                  className="video-link border border-trinidad-500 group transition-all hover:border-transparent hover:border-opacity-0 rounded-3xl p-3 cursor-pointer"
                  onClick={() => {
                    setVideoSource(video.uri.split("/")[2]);
                    setIsVideoOpen(true);
                    setVideoPaddingBottom(
                      100 - (video.height / video.width) * 100
                    );

                    setVideoCategory("Comptabilité");
                    setVideoTitle(video.name);
                    setVideoDuration(getDurationFromSeconds(video.duration));
                  }}
                >
                  <div className="relative">
                    <div
                      className="rounded-2xl grayscale hover:grayscale-0"
                      style={{
                        backgroundImage: `url(${video.pictures.base_link})`,
                        height: "150px",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>
                    <p className=" absolute bottom-0 right-0 mr-2 mb-2 inline-block px-1.5 text-white text-xs  bg-midnight-blue-500 rounded-full grayscale-0">
                      {getDurationFromSeconds(video.duration)}
                    </p>
                  </div>

                  <h4 className="text-midnight-blue-500 text-xs font-semibold  p-2">
                    {video.name}
                  </h4>
                  <div className="grid grid-cols-2 px-2 text-sm font-light text-blue-gray-500">
                    <div>
                      {new Date(video.release_time).toLocaleDateString(
                        "fr-BE",
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
      <h3 className="px-10 text-midnight-blue-500 text-xl font-semibold mb-4">
        Stock
      </h3>
      <div className="grid sm:grid-cols-3 px-10 sm:px-20 gap-x-14 gap-y-8 mb-10">
        {videos.length > 0 &&
          videos
            .filter((video) => video.tags.some((tag) => tag.name === "stock"))
            .map((video) => {
              return (
                <div
                  className="video-link border border-trinidad-500 group transition-all hover:border-transparent hover:border-opacity-0 rounded-3xl p-3 cursor-pointer"
                  onClick={() => {
                    setVideoSource(video.uri.split("/")[2]);
                    setIsVideoOpen(true);
                    setVideoPaddingBottom(
                      100 - (video.height / video.width) * 100
                    );

                    setVideoCategory("Stock");
                    setVideoTitle(video.name);
                    setVideoDuration(getDurationFromSeconds(video.duration));
                  }}
                >
                  <div className="relative">
                    <div
                      className="rounded-2xl grayscale hover:grayscale-0"
                      style={{
                        backgroundImage: `url(${video.pictures.base_link})`,
                        height: "150px",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>
                    <p className=" absolute bottom-0 right-0 mr-2 mb-2 inline-block px-1.5 text-white text-xs  bg-midnight-blue-500 rounded-full grayscale-0">
                      {getDurationFromSeconds(video.duration)}
                    </p>
                  </div>

                  <h4 className="text-midnight-blue-500 text-xs font-semibold  p-2">
                    {video.name}
                  </h4>
                  <div className="grid grid-cols-2 px-2 text-sm font-light text-blue-gray-500">
                    <div>
                      {new Date(video.release_time).toLocaleDateString(
                        "fr-BE",
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
      <h3 className="px-10 text-midnight-blue-500 text-xl font-semibold mb-4">
        Avancé
      </h3>
      <div className="grid sm:grid-cols-3 px-10 sm:px-20 gap-x-14 gap-y-8 mb-10">
        {videos.length > 0 &&
          videos
            .filter((video) => video.tags.some((tag) => tag.name === "avance"))
            .map((video) => {
              return (
                <div
                  className="video-link border border-trinidad-500 group transition-all hover:border-transparent hover:border-opacity-0 rounded-3xl p-3 cursor-pointer"
                  onClick={() => {
                    setVideoSource(video.uri.split("/")[2]);
                    setIsVideoOpen(true);
                    setVideoPaddingBottom(
                      100 - (video.height / video.width) * 100
                    );

                    setVideoCategory("Avancé");
                    setVideoTitle(video.name);
                    setVideoDuration(getDurationFromSeconds(video.duration));
                  }}
                >
                  <div className="relative">
                    <div
                      className="rounded-2xl grayscale hover:grayscale-0"
                      style={{
                        backgroundImage: `url(${video.pictures.base_link})`,
                        height: "150px",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>
                    <p className=" absolute bottom-0 right-0 mr-2 mb-2 inline-block px-1.5 text-white text-xs  bg-midnight-blue-500 rounded-full grayscale-0">
                      {getDurationFromSeconds(video.duration)}
                    </p>
                  </div>

                  <h4 className="text-midnight-blue-500 text-xs font-semibold  p-2">
                    {video.name}
                  </h4>
                  <div className="grid grid-cols-2 px-2 text-sm font-light text-blue-gray-500">
                    <div>
                      {new Date(video.release_time).toLocaleDateString(
                        "fr-BE",
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
      </div> */}
      <div className="grid sm:grid-cols-3 px-10 sm:px-20 gap-x-14 gap-y-8 mb-10">
        <ReactModal
          isOpen={isVideoOpen}
          preventScroll={true}
          onRequestClose={() => {
            setIsVideoOpen(false);
          }}
          onAfterClose={() => {
            setHidden();
          }}
          onAfterOpen={() => {
            setHidden();
          }}
          shouldCloseOnOverlayClick={true}
          overlayElement={
            (props, contentElement) => (
              <div
                {...props}
                onClick={() => {
                  setVideoSource(null);
                  setIsVideoOpen(false);
                }}
              >
                <div className=" flex-col ">{contentElement}</div>
              </div>
            )
            /* Custom Overlay element. */
          }
          style={{
            overlay: {
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.75)",
              zIndex: 100,
              width: "100%",
            },
            content: {
              position: "absolute",
              top: "0px",
              left: "0px",
              right: "0px",
              bottom: "0px",
              overflow: "auto",
              WebkitOverflowScrolling: "touch",
              outline: "none",
              margin: "auto",
              background: "transparent",
              padding: "0",
              border: "0",
            },
          }}
        >
          <div className="flex  items-center relative">
            <div
              className=" absolute top-2 right-2 text-gray-900 bg-gray-50 bg-opacity-30 rounded-full cursor-pointer z-50"
              onClick={() => {
                setVideoSource(null);
                setIsVideoOpen(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 cursor-pointer"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>

            <div className="flex-grow">
              <div className={`bv-video `}>
                <h4 className="mt-24 text-white text-lg  font-regulat text-center mb-2">
                  {videoCategory} - {videoTitle} ({videoDuration})
                </h4>
                {/* <div dangerouslySetInnerHTML={{ __html: embedHtml }} />{" "} */}
                <MyVimeo src={videoSource} />
                {/* <VimeoVideo videoId={videoUri} /> */}
                {/* <Vimeo
                  video={videoSource}
                  autoplay
                  style={
                    {
                      //   paddingBottom: videoPaddingBottom + "%",
                    }
                  }
                /> */}
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    </>
  );
};

export default VideoList;
