import React from "react";

const PegaseLicense = () => {
  return (
    <div className="privacy-page">
      <h1>Licence et conditions d’utilisation du logiciel Pégase</h1>

      <p className=" italic">Dernière mise à jour le 23 mars 2022</p>

      <h2>Entre</h2>
      <p>
        La <span className="font-semibold">Société coopérative ByVets</span>,
        enregistrée à la Banque Carrefour des Entreprises sous le numéro
        0753.452.052, dont le siège social est sis en Belgique à 4500 HUY, rue
        Joseph Wauters, 67, d’une part,
      </p>
      <p>
        Ci-après dénommée{" "}
        <span className=" italic font-semibold">
          « le Bailleur de licence »
        </span>
      </p>
      <h2>ET</h2>
      <p>
        <span className="font-semibold">Vous</span>, d’autre part,
      </p>
      <p>
        Ci-après dénommé{" "}
        <span className=" italic font-semibold">« l’Utilisateur »</span>,
      </p>
      <h2>Il est convenu ce qui suit :</h2>
      <h3>Article 1 – Mise à disposition de Pégase</h3>
      <p>
        En exécution du présent contrat, le Bailleur de licence met à la
        disposition de l’Utilisateur, qui reconnait l’avoir reçu et approuvé, un
        exemplaire exécutable du logiciel Pégase destiné à contribuer à
        l’organisation et à la gestion d’un cabinet de médecine vétérinaire.
      </p>
      <h3>Article 2 – Licence d’utilisation</h3>
      <p>
        En vertu des présentes, l’Utilisateur reçoit du Bailleur de licence
        l’autorisation personnelle de procéder, pendant toute la durée de la
        présente convention, à tous les actes de reproduction et autres qui sont
        prévus à l’article XI.169 du Code de Droit Economique.
        <br /> Cette licence inclut également, dans les limites légales, la
        faculté de procéder aux actes de reproduction visés à l’article XI.170
        du Code de Droit Economique.
      </p>
      <h3>Article 3 – Caractère personnel de la licence</h3>
      <p>
        L’Utilisateur ne pourra utiliser le logiciel Pégase que pour ses propres
        besoins ; il s’interdit de permettre à des tiers, - à l’exception des
        confrères vétérinaires et dans un but purement didactique -, d’utiliser,
        de reproduire, d’observer ou d’étudier le logiciel Pégase, sauf accord
        préalable et écrit du Bailleur de licence. Sauf autorisation du Bailleur
        de licence, la licence dont bénéficie l’Utilisateur ne pourra non plus
        être cédée à un tiers, même en cas de cession du cabinet de
        l’Utilisateur, de mise en location-gérance de celui-ci, de fusion entre
        le cabinet de l’Utilisateur et celui d’un autre vétérinaire ou de toute
        autre opération au terme de laquelle les actifs de l’utilisateur
        seraient transférés à un tiers. Il est également interdit à
        l’Utilisateur de prêter ou donner en location le logiciel Pégase.
        <br />
        Sauf accord exprès du Bailleur de licence, la faillite ou toute
        procédure collective engagée contre l’Utilisateur entrainera de plein
        droit la fin de la licence dont le bénéfice ne pourra pas être regardé
        comme un actif susceptible de réalisation.
      </p>
      <h3>Article 4 – Installation du logiciel</h3>
      <p>
        Le Bailleur de licence donnera à l’Utilisateur un code d’accès à
        introduire sur son site internet de manière à pouvoir télécharger le
        logiciel Pégase. L’Utilisateur pourra donc ensuite aisément installer
        son logiciel via le système d’installation on line mis à disposition par
        le Bailleur de licence.
        <br /> Toutefois, le Bailleur de licence s’engage à fournir une
        assistance technique dans cette phase d’installation si l’Utilisateur
        rencontre des difficultés importantes dans ce processus.
      </p>
      <h3>Article 5 – Maintenance</h3>
      <p>
        La maintenance du logiciel est assurée par le Bailleur de licence
        pendant la durée du contrat.
      </p>
      <h3>Article 6 – Limitation dans l’utilisation du logiciel</h3>
      <p>
        Le logiciel ne pourra être utilisé que sur le matériel affecté au numéro
        de dépôt pharmaceutique du cabinet tel que précisé dans le formulaire
        d'affiliation. L’accès au logiciel Pégase est concédé sous licence en
        tant que produit unique. Il n’est pas permis de dissocier ses
        composants.
        <br />
        L’Utilisateur ne pourra pas procéder à la reconstitution de la logique
        du logiciel, à sa décompilation ou à son désassemblage. En cas de
        nécessité et pour les motifs légitimes qui sont prévus par la loi, le
        Bailleur de licence procurera à l’Utilisateur, - et à sa première
        demande -, les informations nécessaires pour assurer l’interopérabilité
        du logiciel Pégase avec d’autres programmes informatiques.
      </p>
      <h3>Article 7 – Propriété intellectuelle</h3>
      <p>
        L’octroi de la présente licence d’utilisation ne comprend ni ne confère
        en aucun cas le droit de propriété du logiciel Pégase ni de la
        documentation qui l’accompagne ainsi que de toutes les copies qui en
        auront été faites sous quelque forme ou sous quelque support que se
        présentent cet original ou ces copies.
      </p>
      <h3>Article 8 – Non Divulgation</h3>
      <p>
        L’Utilisateur devra prendre toutes les mesures nécessaires avec ses
        employés ou autres personnes ayant accès au logiciel Pégase pour
        s’assurer qu’il ne sera pas mis à disposition ou divulgué à des tierces
        personnes par quelque mode que ce soit. L’Utilisateur s’interdit de
        faire usage des spécifications du logiciel Pégase pour créer ou
        permettre la création d’un autre programme ayant la même destination.
        <br /> Si l’Utilisateur transgressait la règle de la non divulgation, il
        serait pécuniairement responsable du manque à gagner du Bailleur de
        licence, sans préjudice de tous les dommages et intérêts que le
        titulaire des droits de propriété intellectuelle serait autorisé à lui
        réclamer.
      </p>
      <h3>Article 9 – Responsabilité</h3>
      <p>
        Les seules garanties apportées par le Bailleur de licence sont celles
        figurant dans le présent contrat. Sans préjudice à ses obligations de
        maintenance, la responsabilité du Bailleur de licence ne pourra pas être
        recherchée en cas de mauvais fonctionnement du logiciel.
        <br /> L’Utilisateur est seul responsable des dommages qui pourraient
        résulter de l’utilisation du logiciel Pégase. Les informations contenues
        dans la documentation qui accompagne le logiciel Pégase pourront être
        changées à tout moment et sans notice préalable.
      </p>
      <h3>Article 10 – Durée</h3>
      <p>
        L’Utilisateur jouit des bénéfices de la présente licence pendant toute
        la durée de son contrat d'adhésion.
      </p>
      <p>
        Sauf action spéciale mentionnée expressément au formulaire de
        souscription, le présent contrat prend cours à la date de souscription
        et produit ses effets pour une période allant jusqu’au 31 décembre de
        l’année de souscription et pour les deux années civiles suivantes.
        L’adhésion ne pourra donc être interrompue avant ce terme.
      </p>
      <p>
        Après cette période initiale, et sauf application de l’alinéa suivant,
        le présent contrat sera automatiquement reconduit au 1er janvier de
        chaque année pour des périodes successives d’un an.
      </p>
      <p>
        L’Utilisateur peut à tout moment informer le Bailleur de licence, par
        courrier recommandé ou par courriel avec accusé de réception, de sa
        volonté non ambiguë de mettre fin à son contrat d'adhésion. Si le Client
        en informe le Bailleur de licence au moins deux (2) mois avant
        l’expiration de la période de validité́ en cours, le Bailleur de licence
        révoque la licence d'utilisation à la fin de la période de validité́ en
        cours. À défaut, le présent contrat sera automatiquement reconduit pour
        une durée de 1 an tel que prévu à l’alinéa précédent.
      </p>
      <h3>Article 11 – Redevances</h3>
      <p>
        La redevance annuelle de la première année est fixée au formulaire de
        souscription de l’Utilisateur. Son montant fait l’objet d’une
        délibération annuelle par le Bailleur de licence.
      </p>
      <p>
        En cas de résiliation demandée pendant la période initiale de
        souscription, il est précisé que, pour la suite de celle-ci et
        uniquement dans ce cadre, le montant des redevances d’utilisation du
        logiciel Pégase ne pourra excéder la somme payée lors de la dernière
        année à l’exception d’une indexation correspondante à l’indice santé.
      </p>
      <h3>Article 12 – Résolution du contrat</h3>
      <p>
        Le Bailleur de licence se réserve la possibilité de rompre
        unilatéralement le présent contrat en cas de non-respect des conditions
        prévues au présent contrat. Toute résolution entraîne le paiement de
        dommages et intérêts dans le chef de l'Utilisateur fixés a minima à 75%
        de la redevance annuelle pour la période en cours au moment de la
        rupture du présent contrat, sauf à ce que le Bailleur de licence
        justifie d’un préjudice plus important.
      </p>
      <h3>Article 13 – Fin du contrat</h3>
      <p>
        En cas de terminaison du contrat, du fait de son expiration, de sa
        résiliation anticipée, ou pour quelque raison que ce soit, l’Utilisateur
        aura la stricte obligation de cesser immédiatement toute utilisation du
        logiciel Pégase, de supprimer définitivement de ses machines le logiciel
        Pégase ainsi que toute copie qui en aurait été effectuée au titre de
        sauvegarde ou pour un autre motif, de régler toutes les redevances et
        sommes dues au Bailleur de licence dans le mois suivant la fin du
        contrat.
      </p>
      <h3>Article 14 – Juridiction</h3>
      <p>
        Tout litige ayant trait à l’interprétation ou à l’exécution du présent
        contrat est de la juridiction exclusive des tribunaux de Huy.
      </p>
      <br />
    </div>
  );
};

export default PegaseLicense;
