import React from "react";

const VideoTitle = ({ title, isSubCategory }) => {
  if (isSubCategory) {
    return (
      <h4 className="px-10 text-midnight-blue-500 text-base font-medium mb-2">
        {title}
      </h4>
    );
  }
  return (
    <h3 className="px-10 text-midnight-blue-500 text-xl font-semibold mb-4">
      {title}
    </h3>
  );
};

export default VideoTitle;
