import React, { useState } from "react";
import VideoSection from "./VideoSection";

const VideoGroup = ({ category, videos, setters }) => {
  const hasMatchingTag = () => {
    // Get all subcategory tags
    const subcategoryTags = category.subcategories.map((sub) => sub.tag);

    // Loop through each video
    for (const video of Object.values(videos)) {
      // Loop through each tag in the video
      for (const tag of video.tags) {
        // Check if the video's tag matches any subcategory tag
        if (subcategoryTags.includes(tag.tag)) {
          return true; // Match found, return true
        }
      }
    }

    // No matching tag found, return false
    return false;
  };

  return (
    <div>
      {hasMatchingTag() ? (
        <>
          <h3 className="px-10 text-midnight-blue-500 text-xl font-semibold mb-4">
            {category.title}
          </h3>
          {category.subcategories.map((category, index) => {
            return (
              <VideoSection
                key={index}
                category={category}
                isSubCategory={true}
                videos={videos}
                setters={setters}
              />
            );
          })}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default VideoGroup;
