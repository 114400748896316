import React, { useState } from "react";
import Layout from "../components/layout/Layout";
import AffiliationForm from "../components/forms/affiliation/AffiliationForm";
import { ScrollRestoration } from "react-router-dom";
import SEO from "../components/seo/SEO";
import { useLocation } from "react-router-dom";

const AffiliationPage = () => {
  const [promoCodeState, setPromoCodeState] = useState(null);
  // Get the location object
  const location = useLocation();

  // Parse the query parameters
  const queryParams = new URLSearchParams(location.search);
  const promoCode = queryParams.get("code");

  return (
    <Layout>
      <SEO />

      <AffiliationForm promoCode={promoCode} />
    </Layout>
  );
};

export default AffiliationPage;
