import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import ContentButton from "../../components/buttons/ContentButton";
import axios from "axios";
import Layout from "../../components/layout/Layout";
import { useParams } from "react-router-dom";
import SEO from "../../components/seo/SEO";

const AdhesionPage = ({ params }) => {
  return (
    <Layout>
      <SEO />

      <section className="mx-auto sm:w-1/2">
        <h2 className="text-midnight-blue-500 font-semibold text-4xl mb-8">
          Merci pour votre confiance !
        </h2>

        <div className="">
          <p className=" text-blue-gray-500 font-light text-lg mb-8">
            Votre formulaire d’inscription a été envoyé et sera traité dans les
            plus brefs délais. <br />
            <br /> Un mail vous a été envoyé contenant le récapitulatif de votre
            commande. Si vous ne l'avez pas reçu, vérifiez vos spams et si vous
            ne le voyez toujours pas, contactez-nous.
          </p>
        </div>

        <div className="text-center">
          <ContentButton to="/" type="link">
            Retour à l'accueil
          </ContentButton>
        </div>
      </section>
    </Layout>
  );
};

export default AdhesionPage;
