export const replaceHtmlYears = (currentString) => {
  var newString = currentString;
  const currentYear = new Date().getFullYear().toString();
  const nextYear = (parseInt(currentYear) + 1).toString();
  const previousYear = (parseInt(currentYear) - 1).toString();
  newString = newString.replaceAll("{currentYear}", currentYear);
  newString = newString.replaceAll("{nextYear}", nextYear);
  newString = newString.replaceAll("{previousYear}", previousYear);

  return newString;
};
