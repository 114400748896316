import React from "react";
import VideoTitle from "./VideoTitle";
import { getDurationFromSeconds } from "../../utils/secondsToDuration";

const VideoSection = ({ videos, category, title, isSubCategory, setters }) => {
  if (videos.length === 0) {
    return;
  }
  if (
    videos.filter((video) =>
      video.tags.some((tag) => tag.name === category.tag)
    ).length > 0
  ) {
    return (
      <>
        <VideoTitle title={category.title} isSubCategory={isSubCategory} />
        <div className="grid sm:grid-cols-3 px-10 sm:px-20 gap-x-14 gap-y-8 mb-10">
          {videos
            .filter((video) =>
              video.tags.some((tag) => tag.name === category.tag)
            )
            .map((video) => {
              return (
                <div
                  className="video-link border border-trinidad-500 group transition-all hover:border-transparent hover:border-opacity-0 rounded-3xl p-3 cursor-pointer"
                  onClick={() => {
                    setters.setVideoSource(video.uri.split("/")[2]);
                    setters.setIsVideoOpen(true);
                    setters.setVideoPaddingBottom(
                      100 - (video.height / video.width) * 100
                    );
                    setters.setVideoCategory(category.title);
                    setters.setVideoTitle(video.name);
                    setters.setVideoDuration(
                      getDurationFromSeconds(video.duration)
                    );
                    // setEmbedHtml(video.embed.html);
                  }}
                >
                  <div className="relative">
                    <div
                      className="rounded-2xl grayscale hover:grayscale-0"
                      style={{
                        backgroundImage: `url(${video.pictures.base_link})`,
                        height: "150px",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>
                    <p className=" absolute bottom-0 right-0 mr-2 mb-2 inline-block px-1.5 text-white text-xs  bg-midnight-blue-500 rounded-full grayscale-0">
                      {getDurationFromSeconds(video.duration)}
                    </p>
                  </div>

                  <h4 className="text-midnight-blue-500 text-xs font-semibold  p-2">
                    {video.name}
                  </h4>
                  <div className="grid grid-cols-2 px-2 text-sm font-light text-blue-gray-500">
                    <div>
                      {new Date(video.release_time).toLocaleDateString(
                        "fr-BE",
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </>
    );
  }

  return null;
};

export default VideoSection;
